/**
 * 券组件需要使用的多语言keys
 */
export const LANGUAGE_KEYS = [
  'SHEIN_KEY_PC_30596',  // Capped at {0}	
  'SHEIN_KEY_PC_30598',  // Category Coupon
  'SHEIN_KEY_PC_30722',  // Shipping Coupon
  'SHEIN_KEY_PC_30720',  // Special Coupon
  'SHEIN_KEY_PC_30721',  // Store Coupon	
  'SHEIN_KEY_PC_30603',  // Shop
  'SHEIN_KEY_PC_30604',  // Claim
  'SHEIN_KEY_PC_30597',  // {0} OFF subscription fee
  'SHEIN_KEY_PC_30381',  // Orders {0}+
  'SHEIN_KEY_PC_30709',  // Pay with {0}
  'SHEIN_KEY_PC_30710',  // Stackable
  'SHEIN_KEY_PC_30599',  // Best choice
  'SHEIN_KEY_PC_30712',  // NEW
  'SHEIN_KEY_PC_30725',  // Expires in {0}
  'SHEIN_KEY_PC_30726',  // Code:{0}
  'SHEIN_KEY_PC_30731',  // {0} Max
  // 水印多语言
  'SHEIN_KEY_PC_30762',  // Expired
  'SHEIN_KEY_PC_30763',  // All claimed
  'SHEIN_KEY_PC_30764',  // Expanded
  'SHEIN_KEY_PC_30765',  // Used
  'SHEIN_KEY_PC_30766',  // Claimed
  'SHEIN_KEY_PC_31049',  // Invalid
  'SHEIN_KEY_PC_26684',
  'SHEIN_KEY_PC_22087',
  'SHEIN_KEY_PC_32932',
  // 附加组件
  'SHEIN_KEY_PC_30932',  // Add

  // 适用范围多语言
  'SHEIN_KEY_PC_22045',
  'SHEIN_KEY_PC_24829',
  'SHEIN_KEY_PC_24828',
  'SHEIN_KEY_PC_24830',
  'SHEIN_KEY_PC_22031',
  'SHEIN_KEY_PC_22032',
  'SHEIN_KEY_PC_22033',
  'SHEIN_KEY_PC_22482',
  'SHEIN_KEY_PC_22041',
  'SHEIN_KEY_PC_22042',
  'SHEIN_KEY_PC_22424',
  'SHEIN_KEY_PC_22529',
  'SHEIN_KEY_PC_27380',
  'SHEIN_KEY_PC_27379',
  'SHEIN_KEY_PC_27381',
  'SHEIN_KEY_PC_27382',
  'SHEIN_KEY_PC_27095',
  'SHEIN_KEY_PC_27291',
  'SHEIN_KEY_PC_27609',
  'SHEIN_KEY_PC_27293',
  'SHEIN_KEY_PC_27531',
  'SHEIN_KEY_PC_27532',
  'SHEIN_KEY_PC_24721',
  'SHEIN_KEY_PC_27290',
  'SHEIN_KEY_PC_22044',
  'SHEIN_KEY_PC_24713',
  'SHEIN_KEY_PC_24715',
  'SHEIN_KEY_PC_27292',
  'SHEIN_KEY_PC_24723',
  'SHEIN_KEY_PC_27417',
  'SHEIN_KEY_PC_27415',
  'SHEIN_KEY_PC_27421',
  'SHEIN_KEY_PC_27419',
  'SHEIN_KEY_PC_27418',
  'SHEIN_KEY_PC_27416',
  'SHEIN_KEY_PC_27422',
  'SHEIN_KEY_PC_27420',
  'SHEIN_KEY_PC_28965',
  'SHEIN_KEY_PC_28964',
  'SHEIN_KEY_PC_28963',
  'SHEIN_KEY_PC_28962',
  'SHEIN_KEY_PC_24714',
  'SHEIN_KEY_PC_24716',
  'SHEIN_KEY_PC_24722',
  'SHEIN_KEY_PC_24724',
  'SHEIN_KEY_PC_23860',
  'SHEIN_KEY_PC_17344',
  'SHEIN_KEY_PC_28847',
  'SHEIN_KEY_PC_28835',
  'SHEIN_KEY_PC_28836',
  'SHEIN_KEY_PC_18190',
  'SHEIN_KEY_PC_17694',
  'SHEIN_KEY_PC_23167',
  'SHEIN_KEY_PC_17683',
  'SHEIN_KEY_PC_30832',
  'SHEIN_KEY_PC_30829',
  'SHEIN_KEY_PC_28846',
  'SHEIN_KEY_PC_28834',
  'SHEIN_KEY_PC_30830',
  'SHEIN_KEY_PC_28967',
  'SHEIN_KEY_PC_30831',
  'SHEIN_KEY_PC_29099',
  'SHEIN_KEY_PC_29607',
  'SHEIN_KEY_PC_17440',
]
