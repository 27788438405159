<template>
  <CouponItem
    :data="couponData"
    :coupon-config="bffCouponConfig"
    :language="language"
    :expanded="expanded"
    :is-for-bff="isForBff"
    @button-click="handleClickEvent"
  />
</template>

<script setup>

import { computed, defineEmits } from 'vue'
import CouponItem from './CouponItem.vue'

const emits = defineEmits(['button-click'])


const props = defineProps({
  // 优惠券数据
  data: {
    type: Object,
    default: () => {}
  },
  //使用方配置类
  couponConfig: {
    type: Object,
    default: () => {}
  },
  //多语言
  language: {
    type: Object,
    default: () => {}
  },
  expanded: {
    type: String,
    default: ''
  },
  // 是否走 bff 融合
  isForBff: {
    type: Boolean,
    default: false
  }
})

const couponData = computed(() => {
  return {
    ...props.data,
    ...props.couponConfig,
    waterMark: currentWaterMark.value,
    buttonInfo: currentButtonInfo.value,
    categoryLabelList: [...currentCategoryLabel.value],
    addModule: {
      ...props.data.addModule, 
      type: props.data?.addModule ?  String(props.data?.addModule?.type) : '0'
    }
  }
})

const bffCouponConfig = computed(() => {
  return {
    ...props.data,
    ...props.couponConfig,
    waterMark: currentWaterMark.value,
    buttonInfo: currentButtonInfo.value,
    categoryLabelList: [...currentCategoryLabel.value],
    addModule: {
      ...props.data.addModule, 
      type: props.data?.addModule ?  String(props.data?.addModule?.type) : '0'
    }
  }
})

const currentWaterMark = computed(() => {
  let waterMark = props.couponConfig.waterMark === undefined ? props.data.waterMark : props.couponConfig.waterMark
  return !!waterMark ? parseInt(waterMark) : 0
})

const currentButtonInfo = computed(() => {
  let buttonInfo = props.couponConfig.buttonInfo === undefined ? props.data?.buttonInfo?.type || props.data?.buttonInfo : props.couponConfig.buttonInfo
  return !!buttonInfo ? String(buttonInfo) : '0'
})

const currentCategoryLabel = computed(() => {
  return props.data?.categoryLabelList?.map(v => v.title) || []
})


const handleClickEvent = (clickInfo) => {
  emits('button-click', clickInfo)
}
</script>
